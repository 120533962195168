import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Paper, Typography, Stack, TextField, Tooltip, IconButton, ClickAwayListener } from '@mui/material';
import { Box } from '@mui/system';
import { LuInfo } from "react-icons/lu";
import * as yup from 'yup';
import './EditableInfo.css';

const EditableInfo = ({ currentInfo, currentUsername, onHandleEditInfo }) => {
    const { t } = useTranslation();
    const [titleCount, setTitleCount] = useState(currentInfo.title.length ?? 0);
    const [descCount, setDescCount] = useState(currentInfo.description.length ?? 0);
    const [open, setOpen] = useState(false);
    
    const titleSchema = yup.object({
        title: yup
        .string(t("profile.info.name.string"))
        .max(50),
    });

    const descriptionSchema = yup.object({
        description: yup
        .string(t("profile.info.desc.string"))
        .max(200),
    });

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(!open);
    };

    const [errorTitle, setErrorTitle] = useState(currentInfo?.title.length > 0 ? !titleSchema.isValidSync({ title: currentInfo?.title }) : false);
    const [errorDescription, setErrorDescription] = useState(currentInfo?.description.length > 0 ? !descriptionSchema.isValidSync({ description: currentInfo?.description }) : false);

    const handleChangeCustomTitle = (event) => {
        titleSchema.validate({ title: event.target.value })
        .then((valid) => {setErrorTitle(false)})
        .catch((err) => {setErrorTitle(true)});
        onHandleEditInfo('title', event.target.value); 
        setTitleCount(event.target.value.length);
    }

    const handleChangeCustomDescription = (event) => {
        descriptionSchema.validate({ description: event.target.value })
        .then((valid) => {setErrorDescription(false)})
        .catch((err) => {setErrorDescription(true)});
        onHandleEditInfo('description', event.target.value); 
        setDescCount(event.target.value.length);
    }
    
    return (
        <Paper className='profile-editable-info' variant='outlined' elevation={0} sx={{p: 2}}>
            <Box className='editable-info-header'>
                <Typography variant="subtitle1" sx={{ color: 'text.primary'}}>
                    {t("profile.info.title")}
                </Typography>
            </Box>
            <Grid container xs={12} >
                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', marginTop: '1rem', marginBottom: '1rem' }}>
                    <Stack className='editable-info-options' spacing={2}>
                        <div className='editable-info-title'>
                            <Typography variant="subtitle1" sx={{ color: 'text.secondary'}}>
                                {t("profile.info.name.title")}
                            </Typography>
                        </div>
                        <TextField
                            placeholder={currentUsername ?? t("profile.info.name.txt-name")}
                            variant="outlined"
                            fullWidth
                            value={currentInfo?.title}
                            error={errorTitle}
                            helperText={`${titleCount ?? '0'}/50 ${t("profile.info.number-characters")}`}
                            onChange={handleChangeCustomTitle}
                            InputProps={{
                                endAdornment: <ClickAwayListener onClickAway={handleTooltipClose}>
                                    <div>
                                    <Tooltip
                                        PopperProps={{
                                        disablePortal: true,
                                        }}
                                        onClose={handleTooltipClose}
                                        open={open}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        placement="top"
                                        arrow
                                        title={t("profile.info.name.name-info")}
                                    >
                                        <IconButton onClick={handleTooltipOpen} className='editable-info-tooltip' color="primary" size="small">
                                            <LuInfo />
                                        </IconButton>
                                    </Tooltip>
                                    </div>
                                </ClickAwayListener>
                              }}
                        />
                        <Typography className='editable-info-description' variant="subtitle1" sx={{ color: 'text.secondary', margin: 0 }}>
                            {t("profile.info.desc.title")}
                        </Typography>
                        <TextField
                            placeholder={t("profile.info.desc.txt-desc")}
                            multiline
                            maxRows={5}
                            fullWidth
                            value={currentInfo?.description}
                            error={errorDescription}
                            helperText={`${descCount ?? '0'}/200 ${t("profile.info.number-characters")}`}
                            onChange={handleChangeCustomDescription}
                        />
                    </Stack>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default EditableInfo;