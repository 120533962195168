import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from 'react-router-dom';
import { Container, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './NotFound.css';

function NotFound() {
  const { t } = useTranslation();
  let location = useLocation();
  const [errorFrom, setErrorFrom] = useState('');
  const [user, setUser] = useState('');

  useEffect(() => {
    setErrorFrom(location.state?.from);
    setUser(location.state?.userNotFound)
  }, [location.state]);

  return (
    <div className="section-not-found">
      <Helmet>
        <title>{t("error-page.title")}</title>
      </Helmet>
      <Container maxWidth="sm">
        <Grid
          container
          className="usernotfound-grid-container"
          direction="column"
          justifyContent="center"
          alignItems="stretch"
        >
          {errorFrom === 'profile' ?
            <Typography variant="h3" component="h3" align='center' className='title-not-found'>
              {t("error-page.user-start")} {user} {t("error-page.user-end")}
            </Typography>
          :
            <Typography variant="h3" component="h3" align='center' className='title-not-found'>
              {t("error-page.page-not-found")}
            </Typography>
          }
        </Grid>
      </Container>
    </div>
  );
}

export default NotFound;
