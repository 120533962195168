import './Footer.css';
import React, { useState } from 'react';
import { Container, Grid, IconButton, Typography, Divider, ToggleButtonGroup, ToggleButton, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IconContext } from "react-icons";
import { BsInstagram } from "react-icons/bs";
import { FaXTwitter } from "react-icons/fa6";
import { APP_VERSION } from "../../constants";

const Footer = () => {
    const { i18n, t } = useTranslation();
    const [language, setLanguage] = useState(i18n.resolvedLanguage);

    const handleLanguage = (event, newLanguage) => {
        if (newLanguage !== null) {
            setLanguage(newLanguage);
            i18n.changeLanguage(newLanguage);
        }
    };

    return (
        <Container className='section-footer' maxWidth="xl">
            <Grid
                container
                className='footer-container'
            >
                <Grid className='footer-social-media' item xs={12} sm={6}>
                    <IconContext.Provider value={{ color: "rgba(255,255,255,0.7)", className: "react-icons-color" }}>
                        <IconButton color="primary" aria-label="" size="small" href="https://twitter.com/linkerto_me" target="_blank">
                            < FaXTwitter fontSize={20}/>
                        </IconButton>
                        <Typography variant="body2" sx={{ color: 'text.secondary'}}>
                            <span className='separate-dot'>•</span>
                        </Typography>
                        <IconButton color="primary" aria-label="" size="small" href="https://www.instagram.com/linkerto.me/" target="_blank">
                            < BsInstagram fontSize={20}/>
                        </IconButton>
                    </IconContext.Provider>
                    <Divider component={'div'} className='divider-footer' flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
                    <ToggleButtonGroup
                        className='toggle-group-language'
                        value={language}
                        exclusive
                        onChange={handleLanguage}
                        aria-label="select language"
                    >
                        <ToggleButton value="es" aria-label="language es">
                            ES
                        </ToggleButton>
                        <ToggleButton value="en" aria-label="lenguage en">
                            EN
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <Grid className='footer-copyright' item xs={12} sm={6}>
                    <Typography variant="body2" sx={{ color: 'text.secondary'}}>
                        © 2022 - 2024
                    </Typography>
                    <Divider component={'div'} className='divider-footer' flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
                    <Typography variant="body2" sx={{ color: 'text.secondary'}}>
                        {/* {`v${APP_VERSION}`} */}Linkerto&reg;
                    </Typography>
                </Grid>
            </Grid>
        </Container>
  )
}

export default Footer;