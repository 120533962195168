import './PasswordReset.css';
import React, {useEffect, useState, useRef, useContext} from 'react';
import * as yup from 'yup';
import { Container, Grid, Box, Paper, CircularProgress, Typography, InputAdornment, IconButton, TextField, Button } from '@mui/material';
import { useFormik } from 'formik';
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Snackbar from '../../Components/Snackbar/Snackbar';
import useQuery from '../../hooks/useQuery';
import MongoContext from "../../MongoContext";

const PasswordReset = () => {
    const { app, user } = useContext(MongoContext);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const query = useQuery();
    const [isComplete, setIsComplete] = useState(false);
    const [loading, setLoading] = useState(false);
    const [badRequest, setBadRequest] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const snackbar = useRef(null);
  
    const submitHandler = async (values) => {
      setLoading(true);
      try {
        await app.emailPasswordAuth.resetPassword({
            password: values.password,
            token: query.get('token'),
            tokenId: query.get('tokenId'),
        });
        snackbar.current.open('success', t("recovery.success"));
        setIsComplete(true);
      } catch (err) {
        console.log(err);
        switch(err.statusCode) {
          default:
            setErrorMessage(t("recovery.error.bad-request"));
            break;
        }
        setBadRequest(true);
      }
      setLoading(false);
    }

    const userSchema = yup.object({
        password: yup
        .string(t("recovery.password-string"))
        .min(8, t("recovery.password-min"))
        .required(t("recovery.password-required")),
        passwordRepeat: yup
        .string(t("recovery.password-string"))
        .min(8, t("recovery.password-min"))
        .oneOf([yup.ref('password'), null], t("recovery.password-match"))
        .required(t("recovery.password-required")),
    });

    const formik = useFormik({
        initialValues: {
            password: '',
            passwordRepeat: ''
        },
        validationSchema: userSchema,
        onSubmit: (values) => {
            submitHandler(values)
        },
    });

    const handleChange = (e) => {
        badRequest && setBadRequest(false);
        formik.handleChange(e);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {	
        setBadRequest(false);
      }, [navigate, user]);

  return (
    <div className="section-reset-password">
        <Container className='section-resetpass-body' maxWidth="md">
            <Grid
              container
              className="resetpass-grid-container"
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
            <Box className="resetpass-box">
              <Paper className="resetpass-paper" variant='outlined' elevation={0}>
                {
                  isComplete ?
                  <Box
                  className="resetpass-complete"
                  component="div"
                  >
                    <Typography variant="h5"  component="div" color="primary">
                      {t("recovery.title")}
                    </Typography>
                    <div className='resetpass-complete-body'>
                      <Typography variant="body1" textAlign='left' component="div" color="primary" gutterBottom>
                        {t("recovery.subtitle")}
                      </Typography>
                      <Typography textAlign='left' variant="body2" >
                        {t("recovery.body-1")}
                      </Typography>
                    </div>
                    <Button className='resetpass-btn' component={Link} to="/login" color="primary" variant="contained" size="large" type="submit">
                      {t("recovery.commit-1")}
                    </Button>
                  </Box>
                  :
                  <Box
                  className="resetpass-form"
                  component="form"
                  onSubmit={formik.handleSubmit}
                  autoComplete="off"
                  >
                    <div className='resetpass-form-header'>
                        <div className='resetpass-form-header-title'>
                            <Typography variant="h5"  component="div" color="primary">
                              {t("recovery.title")}
                            </Typography>
                        </div>
                        <Typography className='resetpass-form-header-body' textAlign='left' color='primary' variant="body1" >
                          {t("recovery.body-2")}
                        </Typography>
                    </div>
                    <TextField
                      color="primary"
                      fullWidth
                      id="password"
                      name="password"
                      label={t("recovery.password")}
                      type={showPassword ? 'text' : 'password'}
                      margin="normal"
                      value={formik.values.password}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                        </IconButton>
                      </InputAdornment>,
                      }}
                      onChange={(e) => handleChange(e)}
                      error={formik.touched.password && Boolean(formik.errors.password) ? Boolean(formik.errors.password) : badRequest}
                      helperText={formik.touched.password && formik.errors.password}
                      disabled={loading}
                    />
                    <TextField
                      color="primary"
                      fullWidth
                      id="passwordRepeat"
                      name="passwordRepeat"
                      label={t("recovery.password")}
                      type={showPassword ? 'text' : 'password'}
                      margin="normal"
                      value={formik.values.passwordRepeat}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                        </IconButton>
                      </InputAdornment>,
                      }}
                      onChange={(e) => handleChange(e)}
                      error={formik.touched.passwordRepeat && Boolean(formik.errors.passwordRepeat) ? Boolean(formik.errors.passwordRepeat) : badRequest}
                      helperText={formik.touched.passwordRepeat && formik.errors.passwordRepeat}
                      disabled={loading}
                    />
                    {
                      badRequest &&
                      <Typography variant="body2" className='resetpass-request-error'>
                        {errorMessage}
                      </Typography>
                    }
                    <Button className='resetpass-btn' disabled={loading} color="primary" variant="contained" size="large" type="submit">
                      {loading && <CircularProgress size={27} color="secondary" />}
                      {!loading && <>{t("recovery.commit-2")}</>}
                    </Button>
                  </Box>
                }
              </Paper>
            </Box>
          </Grid>
        </Container>
        <Snackbar ref={snackbar}/>
    </div>
  )
}

export default PasswordReset;