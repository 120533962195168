import './App.css';
import React, {useState, useEffect} from "react";
import MongoContext from "./MongoContext";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import * as Realm from 'realm-web';
import Authentication from './Pages/Authentication/Authentication';
import Logout from './Pages/Logout/Logout';
import UserLinks from './Pages/UserLinks/UserLinks';
import NotFound from './Pages/NotFound/NotFound';
import Home from './Pages/Home/Home';
import Profile from './Pages/Profile/Profile';
import SendPasswordReset from './Pages/SendPasswordReset/SendPasswordReset';
import PasswordReset from './Pages/PasswordReset/PasswordReset';
import TermsAndConditions from './Pages/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
import DefaultLayout from './Components/DefaultLayout/DefaultLayout';
import UserLinksLayout from './Components/UserLinksLayout/UserLinksLayout';
import { isAnon } from './utils';

function App() {
  const [client, setClient] = useState(null)
  const [user, setUser] = useState(null)
  const [app, setApp] = useState(new Realm.App({id: process.env.REACT_APP_REALM_APP_ID}))

  useEffect(() => {
    async function init () {
      if (!user) {
        setUser(app.currentUser ? app.currentUser : null)
      }
      if (!client) {
        setClient(app.currentUser ? app.currentUser.mongoClient('mongodb-atlas') : null)
      }
    }
    init();
  }, [app, client, user]);

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      type: 'dark',
      primary: {
        main: '#e8e7e7',
        contrastText: '#0f1c29',
        dark: '#fff',
      },
      secondary: {
        main: '#89253e',
        contrastText: '#fff'
      },
      background: {
        default: '#0f1c29',
        paper: '#122435',
      },
      text: {
        primary: '#fff',
        secondary: 'rgba(255,255,255,0.7)',
        disabled: 'rgba(255,255,255,0.5)',
      },
    },
    typography: {
      fontFamily: 'Poppins, sans-serif',
    },
  });
  
  return (
    <MongoContext.Provider value={{app, client, user, setClient, setUser, setApp}}>
      <ThemeProvider theme={darkTheme}>
        <BrowserRouter>
          <Routes>
            <Route element={<DefaultLayout user={user}/>}>
              <Route path="/" Component={Home}/>
              <Route path="/signup" element={<Authentication type="create"/>}/>
              <Route path="/login" element={<Authentication type="login"/>} />
              <Route path="/logout" element={!isAnon(user) ? <Logout /> : <Navigate to="/login" />} />
              <Route path="/profile" element={!isAnon(user) ? <Profile /> : <Navigate to="/login" />}/>
              <Route path="/sendPasswordReset" Component={SendPasswordReset}/>
              <Route path="/passwordReset" Component={PasswordReset}/>
              <Route path="/termsAndConditions" Component={TermsAndConditions}/>
              <Route path="/privacyPolicy" Component={PrivacyPolicy}/>
              <Route path="/404" Component={NotFound}/>
              <Route path="*" element={<Navigate to="/404" />}/>
            </Route>
            <Route element={<UserLinksLayout isLogged={!isAnon(user)}/>}>
              <Route path="/:user" Component={UserLinks}/>
            </Route>
          </Routes>
        </BrowserRouter>    
      </ThemeProvider>
    </MongoContext.Provider>
  );
}

export default App;
