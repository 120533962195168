import './TermsAndConditions.css';
import { Container, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { LAST_UPDATE_TC } from "../../constants";
import { Link } from "react-router-dom";
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';

const locales = {
	es: es,
	en: en
};

function TermsAndConditions() {
  const { t, i18n } = useTranslation();
  return (
    <div className="section-tc">
      <div className="section-tc-container">
        <Container className='section-tc-body' maxWidth="md">
            <Grid
                container
                className="tc-grid-container"
                direction="column"
                justifyContent="center"
                alignItems="stretch"
            >
                <Typography className='title-tc' variant="h3" gutterBottom component="h3" align='center'>
                    {t("terms-and-conditions.title")}
                </Typography>
                <Typography className='subtitle-body' variant="subtitle2" gutterBottom align='left'>
                    {t("terms-and-conditions.last-update")}: {format(LAST_UPDATE_TC, 'PPP', {locale: locales[i18n.language]})}
                </Typography>
                <Typography className='text-body' variant="body2" gutterBottom align='left'>
                    {t("terms-and-conditions.description")}
                </Typography>
                <Typography className='subtitle-body' variant="body2" gutterBottom align='left'>
                    1. {t("terms-and-conditions.dot-1-title")}:
                </Typography>
                <Typography className='text-body' variant="body2" gutterBottom align='left'>
                    {t("terms-and-conditions.dot-1-value")}
                </Typography>
                <Typography className='subtitle-body' variant="body2" gutterBottom align='left'>
                    2. {t("terms-and-conditions.dot-2-title")}:
                </Typography>
                <Typography className='text-body' variant="body2" gutterBottom align='left'>
                    {t("terms-and-conditions.dot-2-value")}
                </Typography>
                <Typography className='subtitle-body' variant="body2" gutterBottom align='left'>
                    3. {t("terms-and-conditions.dot-3-title")}:
                </Typography>
                <Typography className='text-body' variant="body2" gutterBottom align='left'>
                    {t("terms-and-conditions.dot-3-value")}
                </Typography>
                <Typography className='subtitle-body' variant="body2" gutterBottom align='left'>
                    4. {t("terms-and-conditions.dot-4-title")}:
                </Typography>
                <Typography className='text-body' variant="body2" gutterBottom align='left'>
                    {t("terms-and-conditions.dot-4-value")}
                </Typography>
                <Typography className='subtitle-body' variant="body2" gutterBottom align='left'>
                    5. {t("terms-and-conditions.dot-5-title")}:
                </Typography>
                <Typography className='text-body' variant="body2" gutterBottom align='left'>
                    {t("terms-and-conditions.dot-5-value-first")} <Link className="text-link underline" to="/privacyPolicy">{t("terms-and-conditions.privacy-policy-text")}</Link> {t("terms-and-conditions.dot-5-value-second")}
                </Typography>
                <Typography className='subtitle-body' variant="body2" gutterBottom align='left'>
                    6. {t("terms-and-conditions.dot-6-title")}:
                </Typography>
                <Typography className='text-body' variant="body2" gutterBottom align='left'>
                    {t("terms-and-conditions.dot-6-value")}
                </Typography>
                <Typography className='subtitle-body' variant="body2" gutterBottom align='left'>
                    7. {t("terms-and-conditions.dot-7-title")}:
                </Typography>
                <Typography className='text-body' variant="body2" gutterBottom align='left'>
                    {t("terms-and-conditions.dot-7-value-first")} <a href="mailto:contact@linkerto.com" className="text-link underline">contact@linkerto.com</a> {t("terms-and-conditions.dot-7-value-second")}
                </Typography>
                <Typography className='subtitle-body' variant="body2" gutterBottom align='left'>
                    8. {t("terms-and-conditions.dot-8-title")}:
                </Typography>
                <Typography className='text-body' variant="body2" gutterBottom align='left'>
                    {t("terms-and-conditions.dot-8-value")}
                </Typography>
                <Typography className='subtitle-body' variant="body2" gutterBottom align='left'>
                    {t("terms-and-conditions.contact-title")}:
                </Typography>
                <Typography className='text-body' variant="body2" gutterBottom align='left'>
                    {t("terms-and-conditions.contact-value")} <a href="mailto:contact@linkerto.com" className="text-link underline">contact@linkerto.com</a>.
                </Typography>
            </Grid>
        </Container>
      </div>
    </div>
  );
}

export default TermsAndConditions;
