import React from 'react';
import { Grid, Paper, Typography, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { IconContext } from "react-icons";
import { BiLink } from "react-icons/bi";
import { HiPlus } from "react-icons/hi";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from 'react-i18next';
import Link from './Link/Link';
import './EditableLinks.css';

const EditableLinks = ({ currentLinks, onHandleAddLink, onHandleDeleteLink, onHandleEditLink, onHandleEditPosition }) => {
    const { t } = useTranslation();

    const handleAddLink = () => {
        onHandleAddLink({
            id_link: parseInt(Math.floor(Math.random() * Date.now()).toString().slice(0, 5)),
            social_url: "",
            position: currentLinks.length > 0 ? Math.max(...currentLinks.map(item => item.position)) + 1 : 0,
            social_name: "",
            icon_name:""
        });
    }

    const onDragEnd = (result) => {
        if(!result.destination)
            return;
        if(result.type === 'link'){
            onHandleEditPosition(result.destination.index, result.source.index)
        }
    }

    return (
        <Paper className='profile-editable-links' variant='outlined' elevation={0} sx={{p: 2}}>
            <Box className='editable-links-header'>
                <Typography variant="subtitle1" sx={{ color: 'text.primary'}}>
                    {t("profile.links.title")}
                </Typography>
                <IconButton color="primary" aria-label="add link" onClick={handleAddLink}>
                    <HiPlus />
                </IconButton>
            </Box>
            <Grid container xs={12} >
                <Grid className='profile-grid-editable-links' item xs={12} sx={{ display: 'flex', alignItems: 'center', marginTop: '1rem', marginBottom: '1rem'}}>
                    {
                        currentLinks.length > 0 ?
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId='dropLink' type='link'>
                                {(provided) => (
                                    <div ref={provided.innerRef} 
                                        {...provided.droppableProps}
                                    >
                                        {currentLinks.map((link, index) => (
                                            <Draggable index={index} draggableId={link.id_link.toString()} key={link.id_link}>
                                                {(provided) => (
                                                    <div ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                    >
                                                        <Link
                                                            key={link.id_link}
                                                            link={link}
                                                            onHandleDnd={provided.dragHandleProps}
                                                            onHandleDeleteLink={onHandleDeleteLink}
                                                            onHandleEditLink={onHandleEditLink}
                                                        />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>:
                        <Box className='editable-links-empty-links'>
                            <IconContext.Provider value={{ color: "#ffffff80", className: "react-icons-color" }}>
                                <BiLink fontSize={50}/>
                            </IconContext.Provider>
                            <Typography variant="h6" sx={{ color: 'text.disabled'}}>
                                {t("profile.links.empty")}
                            </Typography>
                        </Box>
                    }
                </Grid>
            </Grid>
        </Paper>
    );
};

export default EditableLinks;