import './EditableTheme.css'
import React, { useState } from 'react';
import { Paper, Grid, Typography, Stack, Popover, Button, Select, MenuItem } from '@mui/material';
import { Box } from '@mui/system';
import { RgbaColorPicker } from "react-colorful";
import { useTranslation } from 'react-i18next';
import { widthOptions } from '../EditableTheme/WidthOptions';

const EditableTheme = ({ onHandleEditTheme, baseTitleClrUsr, baseButtonTxtClr, baseBackgroundClrBtn, baseBorderBtn, baseBorderWidth, baseLeftGrd, baseRightGrd }) => {
    const { t } = useTranslation();
    const [openBtnUsrTxtColor, setOpenBtnUsrTxtColor] = useState(null);
    const [openBtnTxtColor, setOpenBtnTxtColor] = useState(null);
    const [openBtnBgColor, setOpenBtnBgColor] = useState(null);
    const [openBtnBorder, setOpenBtnBorder] = useState(null);
    const [openBtnLeftGradient, setOpenBtnLeftGradient] = useState(null);
    const [openBtnRightGradient, setOpenBtnRightGradient] = useState(null);
    const [btnUsrTxtColor,  setBtnUsrTxtColor] = useState({a: parseFloat(baseTitleClrUsr[3]), b: parseInt(baseTitleClrUsr[2]), g: parseInt(baseTitleClrUsr[1]), r: parseInt(baseTitleClrUsr[0])});
    const [btnTxtColor,  setBtnTxtColor] = useState({a: parseFloat(baseButtonTxtClr[3]), b: parseInt(baseButtonTxtClr[2]), g: parseInt(baseButtonTxtClr[1]), r: parseInt(baseButtonTxtClr[0])});
    const [btnBgColor,  setbtnBgColor] = useState({a: parseFloat(baseBackgroundClrBtn[3]), b: parseInt(baseBackgroundClrBtn[2]), g: parseInt(baseBackgroundClrBtn[1]), r: parseInt(baseBackgroundClrBtn[0])});
    const [btnBrColor,  setbtnBrColor] = useState({a: parseFloat(baseBorderBtn[3]), b: parseInt(baseBorderBtn[2]), g: parseInt(baseBorderBtn[1]), r: parseInt(baseBorderBtn[0])});
    const [btnLeftGradient,  setbtnLeftGradient] = useState({a: parseFloat(baseLeftGrd[3]), b: parseInt(baseLeftGrd[2]), g: parseInt(baseLeftGrd[1]), r: parseInt(baseLeftGrd[0])});
    const [btnRightGradient,  setbtnRightGradient] = useState({a: parseFloat(baseRightGrd[3]), b: parseInt(baseRightGrd[2]), g: parseInt(baseRightGrd[1]), r: parseInt(baseRightGrd[0])});
    const [widthSelected, setWidthValue] = useState(baseBorderWidth ? widthOptions.find(item => item.label === baseBorderWidth) : null);

    const handleClick = event => setOpenBtnUsrTxtColor(event.currentTarget);
    const handleClick2 = event => setOpenBtnTxtColor(event.currentTarget);
    const handleClick3 = event => setOpenBtnBgColor(event.currentTarget);
    const handleClick4 = event => setOpenBtnBorder(event.currentTarget);
    const handleClick5 = event => setOpenBtnLeftGradient(event.currentTarget);
    const handleClick6 = event => setOpenBtnRightGradient(event.currentTarget);

    const handleClose = () => {
        setOpenBtnUsrTxtColor(null);
        onHandleEditTheme('title_color_user', `rgba(${btnUsrTxtColor.r}, ${btnUsrTxtColor.g}, ${btnUsrTxtColor.b}, ${btnUsrTxtColor.a})`)
    };

    const handleClose2 = () => {
        setOpenBtnTxtColor(null);
        onHandleEditTheme('text_color_button', `rgba(${btnTxtColor.r}, ${btnTxtColor.g}, ${btnTxtColor.b}, ${btnTxtColor.a})`)
    };

    const handleClose3 = () => {
        setOpenBtnBgColor(null);
        onHandleEditTheme('background_color_button', `rgba(${btnBgColor.r}, ${btnBgColor.g}, ${btnBgColor.b}, ${btnBgColor.a})`)
    };

    const handleClose4 = () => {
        setOpenBtnBorder(null);
        onHandleEditTheme('border_button', `${widthSelected !== null ? widthSelected.label : '1px' } solid rgba(${btnBrColor.r}, ${btnBrColor.g}, ${btnBrColor.b}, ${btnBrColor.a})`)
    };

    const handleClose5 = () => {
        setOpenBtnLeftGradient(null);
        onHandleEditTheme('left_gradient', `rgba(${btnLeftGradient.r}, ${btnLeftGradient.g}, ${btnLeftGradient.b}, ${btnLeftGradient.a})`)
    };

    const handleClose6 = () => {
        setOpenBtnRightGradient(null);
        onHandleEditTheme('right_gradient', `rgba(${btnRightGradient.r}, ${btnRightGradient.g}, ${btnRightGradient.b}, ${btnRightGradient.a})`)
    };

    const handleChangeBorderWidth = (event) => {
        const selected = event.target.value;
        setWidthValue(selected);
        onHandleEditTheme('border_button', `${selected.label} solid ${`rgba(${btnBrColor.r}, ${btnBrColor.g}, ${btnBrColor.b}, ${btnBrColor.a})`}` );
    };

    const open = Boolean(openBtnUsrTxtColor);
    const open2 = Boolean(openBtnTxtColor);
    const open3 = Boolean(openBtnBgColor);
    const open4 = Boolean(openBtnBorder);
    const open5 = Boolean(openBtnLeftGradient);
    const open6 = Boolean(openBtnRightGradient);
    const id = open ? 'simple-popover-color' : undefined;
    const id2 = open2 ? 'simple-popover-color' : undefined;
    const id3 = open3 ? 'simple-popover-color' : undefined;
    const id4 = open4 ? 'simple-popover-color' : undefined;
    const id5 = open5 ? 'simple-popover-color' : undefined;
    const id6 = open6 ? 'simple-popover-color' : undefined;

    return (
        <Paper className='profile-editable-theme' variant='outlined' elevation={0} sx={{p: 2}}>
            <Box className='editable-theme-header'>
                <Typography variant="subtitle1" sx={{ color: 'text.primary'}}>
                    {t("profile.theme.title")}
                </Typography>
            </Box>
            <Grid container xs={12}>
                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', marginTop: '1rem', marginBottom: '1rem' }}>
                    <Stack className='editable-theme-options' spacing={2}>
                        <Typography variant="subtitle1" sx={{ color: 'text.secondary'}}>
                            {t("profile.theme.user-options.title")}
                        </Typography>
                        <div className="container-button-theme-option">
                            <Typography variant="subtitle2" sx={{ color: 'text.secondary'}}>
                                {t("profile.theme.user-options.color-text")}:
                            </Typography>
                            <Button aria-describedby={id} className='button-theme-option' style={{backgroundColor: `rgba(${btnUsrTxtColor.r}, ${btnUsrTxtColor.g}, ${btnUsrTxtColor.b}, ${btnUsrTxtColor.a}`}} variant="text" onClick={handleClick}>
                            </Button>
                            <Popover
                                id={id}
                                className="popover-theme-option"
                                open={open}
                                anchorEl={openBtnUsrTxtColor}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <RgbaColorPicker color={btnUsrTxtColor} onChange={setBtnUsrTxtColor} />
                            </Popover>
                        </div>
                        <Typography variant="subtitle1" sx={{ color: 'text.secondary'}}>
                            {t("profile.theme.button-options.title")}
                        </Typography>
                        <div className="container-button-theme-option">
                            <Typography variant="subtitle2" sx={{ color: 'text.secondary'}}>
                                {t("profile.theme.button-options.color-text")}:
                            </Typography>
                            <Button aria-describedby={id2} className='button-theme-option' style={{backgroundColor: `rgba(${btnTxtColor.r}, ${btnTxtColor.g}, ${btnTxtColor.b}, ${btnTxtColor.a}`}} variant="text" onClick={handleClick2}>
                            </Button>
                            <Popover
                                id={id2}
                                className="popover-theme-option"
                                open={open2}
                                anchorEl={openBtnTxtColor}
                                onClose={handleClose2}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <RgbaColorPicker color={btnTxtColor} onChange={setBtnTxtColor} />
                            </Popover>
                        </div>
                        <div className="container-button-theme-option">
                            <Typography variant="subtitle2" sx={{ color: 'text.secondary'}}>
                                {t("profile.theme.button-options.color-background")}:
                            </Typography>
                            <Button aria-describedby={id3} className='button-theme-option' style={{backgroundColor: `rgba(${btnBgColor.r}, ${btnBgColor.g}, ${btnBgColor.b}, ${btnBgColor.a}`}} variant="text" onClick={handleClick3}>
                            </Button>
                            <Popover
                                id={id3}
                                className="popover-theme-option"
                                open={open3}
                                anchorEl={openBtnBgColor}
                                onClose={handleClose3}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <RgbaColorPicker color={btnBgColor} onChange={setbtnBgColor} />
                            </Popover>
                        </div>
                        <div className="container-button-theme-option border-options">
                            <Typography variant="subtitle2" sx={{ color: 'text.secondary'}}>
                                {t("profile.theme.button-options.border")}:
                            </Typography>
                            <Button aria-describedby={id4} className='button-theme-option' style={{backgroundColor: `rgba(${btnBrColor.r}, ${btnBrColor.g}, ${btnBrColor.b}, ${btnBrColor.a}`}} variant="text" onClick={handleClick4}>
                            </Button>
                            <Popover
                                id={id4}
                                className="popover-theme-option"
                                open={open4}
                                anchorEl={openBtnBorder}
                                onClose={handleClose4}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <RgbaColorPicker color={btnBrColor} onChange={setbtnBrColor} />
                            </Popover>
                            <Select
                                id="editable-theme-select-border"
                                value={widthSelected}
                                onChange={handleChangeBorderWidth}
                                displayEmpty
                                renderValue={(selected) => {
                                    if (selected === null) {
                                        return t("profile.theme.button-options.width");
                                    }
                                    return selected.label;
                                }}
                            >
                                {widthOptions.map((option) => (
                                    <MenuItem key={option.id} value={option}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <Typography variant="subtitle1" sx={{ color: 'text.secondary'}}>
                            {t("profile.theme.page-options.title")}
                        </Typography>
                        <div className="container-button-theme-option">
                            <Typography variant="subtitle2" sx={{ color: 'text.secondary'}}>
                                {t("profile.theme.page-options.left-gradient")}:
                            </Typography>
                            <Button aria-describedby={id5} className='button-theme-option' style={{backgroundColor: `rgba(${btnLeftGradient.r}, ${btnLeftGradient.g}, ${btnLeftGradient.b}, ${btnLeftGradient.a}`}} variant="text" onClick={handleClick5}>
                            </Button>
                            <Popover
                                id={id5}
                                className="popover-theme-option"
                                open={open5}
                                anchorEl={openBtnLeftGradient}
                                onClose={handleClose5}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <RgbaColorPicker color={btnLeftGradient} onChange={setbtnLeftGradient} />
                            </Popover>
                        </div>
                        <div className="container-button-theme-option">
                            <Typography variant="subtitle2" sx={{ color: 'text.secondary'}}>
                                {t("profile.theme.page-options.right-gradient")}:
                            </Typography>
                            <Button aria-describedby={id6} className='button-theme-option' style={{backgroundColor: `rgba(${btnRightGradient.r}, ${btnRightGradient.g}, ${btnRightGradient.b}, ${btnRightGradient.a}`}} variant="text" onClick={handleClick6}>
                            </Button>
                            <Popover
                                id={id6}
                                className="popover-theme-option"
                                open={open6}
                                anchorEl={openBtnRightGradient}
                                onClose={handleClose6}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <RgbaColorPicker color={btnRightGradient} onChange={setbtnRightGradient} />
                            </Popover>
                        </div>
                    </Stack>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default EditableTheme