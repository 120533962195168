import * as IconFa5 from "react-icons/fa";
import * as IconFa6 from "react-icons/fa6";
import { BsLink45Deg } from "react-icons/bs";

const IconByLink = ({ name, color }) => {
    const IconComponent = IconFa5[name] ?? IconFa6[name];
    const styles = {
        iconLink: {
            color: color
        }
    }

    if (!IconComponent) {
        return <BsLink45Deg style={styles.iconLink} />;
    }

    return <IconComponent style={styles.iconLink} />;
};

function DynamicIcon({ name, color }) {
    return (
        <IconByLink name={name} color={color}/>
    )
};

export default DynamicIcon;