import './PreviewLinks.css';
import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { IconContext } from "react-icons";
import { TbNotesOff } from "react-icons/tb";
import { useTranslation } from 'react-i18next';
import Links from '../Links/Links';

const PreviewLinks = ({ username, links, theme, info }) => {
  const { t } = useTranslation();
  return (
    <Paper className='profile-preview-links' variant='outlined' elevation={0}>
        <Grid className={`profile-grid-preview-links ${username == null && links.length === 0 ? 'empty-preview' : ''}`} style={{background: `linear-gradient(to right, ${theme.left_gradient}, ${theme.right_gradient})`}} container>
          <Grid className={'profile-grid-preview-item'} item xs={12}>
            {username != null || links.length !== 0 ? 
            <>
              <div className='preview-info-header'>
                <Typography style={{color: theme.title_color_user}} className={'profile-preview-username'} variant="h4" component="h4">
                  {!!info.title ? info.title.length > 0 ? info.title : username : username}
                </Typography>
                <Typography style={{color: theme.title_color_user, display: info?.description.length === 0 ? 'none' : 'block'}} className={'profile-preview-body-text'} variant="body1" >
                  {info?.description}
                </Typography>
              </div>
              <Links className='profile-preview-links-container' links={links} theme={theme} origin='preview'/>
            </> :
            <Box className='preview-empty-links'>
              <IconContext.Provider value={{ color: "#ffffff80", className: "react-icons-color" }}>
                  <TbNotesOff fontSize={50}/>
              </IconContext.Provider>
              <Typography variant="h6" sx={{ color: 'text.disabled'}}>
                {t("profile.preview.empty-title")}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.disabled', textAlign: 'center'}}>
                {t("profile.preview.empty-subtitle")}
              </Typography>
            </Box>
            }
          </Grid>
        </Grid>
    </Paper>
  )
}

export default PreviewLinks;