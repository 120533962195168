import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import { isAnon } from "../../utils";
import MongoContext from "../../MongoContext";

const LogOut = () => {
    const { app, setUser, setClient } = useContext(MongoContext);
    let  navigate = useNavigate()

    if (isAnon()) {
        navigate('/')
    }

    useEffect(() => {
        async function logout () {
            await app.currentUser.logOut()
            //login anon user
            setUser(null)
            //set new client
            setClient(null)
        }

        logout()
        navigate('/')
    // eslint-disable-next-line
    }, [app, setClient, setUser])

    return (
        <></>
    )
}

export default LogOut