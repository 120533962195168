import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from "react-helmet";
import { Container, Grid, Typography } from '@mui/material';
import Links from '../../Components/Links/Links';
import './UserLinks.css';

function UserLinks() {
  let { user } = useParams();
  let navigate  = useNavigate();
  const [userFound, setUserFound] = useState('');
  const [linksFound, setLinksFound] = useState([]);
  const [themeFound, setThemeFound] = useState({});
  const [infoFound, setInfoFound] = useState({});
  
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_GET_USER}${user}`)
    .then(function (response) {
      const { data } = response;
      if (response.status === 200 && data.status === 1){
        setUserFound(data.result.name);
        setThemeFound(data.result.theme);
        setInfoFound(data.result.info);
        const linksBefore = data.result.links.sort((a, b) => {
          return a.position - b.position
        });
        setLinksFound(linksBefore);
        //setLoading(false)
      }
      if(response.status === 200 && data.status === 2){
        const userNotFound = {
          state: { from: 'profile', userNotFound: user }
        }
        navigate('/404', userNotFound);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }, [user, navigate])

  const styles = {
    sectionProfile: {
      background: themeFound.left_gradient, // eslint-disable-next-line
      background: `-webkit-linear-gradient(to right, ${themeFound.left_gradient}, ${themeFound.right_gradient})`, // eslint-disable-next-line
      background: `linear-gradient(to right, ${themeFound.left_gradient}, ${themeFound.right_gradient})` // eslint-disable-next-line
    },
    userTittle: {
      color: `${themeFound.title_color_user}`
    }
  }

  return (
      <div className="section-links">
        <Helmet>
            <title>Hi! this is {userFound}</title>
            <body style={`background-image: linear-gradient(to right, ${themeFound.left_gradient}, ${themeFound.right_gradient}); background-image: linear-gradient(to right, ${themeFound.left_gradient}, ${themeFound.right_gradient})`}>
            </body>
        </Helmet>
        <Container className='section-links-body' maxWidth="sm">
          <Grid
            container
            className="user-grid-container"
          >
            <Grid item className="user-grid-item" xs={12}>
              <div className='user-info-header'>
                <Typography style={styles.userTittle} className={'user-name-text'} variant="h3" gutterBottom component="h3">
                  {!!infoFound?.title ? infoFound.title.length > 0 ? infoFound.title : userFound : userFound}
                </Typography>
                <Typography style={{...styles.userTittle, display: !!infoFound?.description ? infoFound?.description.length === 0 ? 'none' : 'block' : 'none'}} className={'user-body-text'} variant="body1" gutterBottom>
                  {infoFound?.description}
                </Typography>
              </div>
              <Links className='user-links-container' links={linksFound} theme={themeFound} origin='public'/>
            </Grid>
          </Grid>
        </Container>
      </div>
  );
}

export default UserLinks;
