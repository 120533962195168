module.exports = {
    isAnon: function (user) {
        return !user || user.identities[0].providerType === 'anon-user'
    },
    isValidateTitle: (title) => {
        return !title || title.length <= 50
    },
    isValidateDesc: (bio) => {
        return !bio || bio.length <= 200
    }
}