import React from 'react';
import Nav from '../Nav/Nav';
import Footer from '../Footer/Footer';
import { Outlet } from 'react-router-dom';
import './DefaultLayout.css'

const DefaultLayout = ({ user }) => {
    return (
            <div className='default-layout'>
                <Nav user={user}/>
                <Outlet />
                <Footer />
            </div>
    )
}

export default DefaultLayout;