import './Link.css';
import React, { useState } from 'react';
import { MdDragIndicator } from  "react-icons/md";
import { TextField, Select, IconButton, MenuItem } from '@mui/material';
import { socialMediaOptions } from './Options';
import { BiTrash } from "react-icons/bi";
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

const ITEM_HEIGHT = 48;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5
    },
  },
};

const Link = ({ link, onHandleDnd, onHandleDeleteLink, onHandleEditLink }) => {
    const { t } = useTranslation();
    const [valueSelected, setSelectedValue] = useState(link.social_name ? socialMediaOptions.find(item => item.label === link.social_name) : null);
    const [errorMessage, setErrorMessage] = useState(t("profile.links.link.valid"));

    const linkSchema = yup.object({
        link: yup
        .string(t("profile.links.link.string"))
        .url()
        .required(),
    });

    const [errorLink, setErrorLink] = useState(link.social_url.length > 0 ? !linkSchema.isValidSync({ link: link.social_url }) : false);

    const handleChangeSocialUrl = (event) => {
        linkSchema.validate({ link: event.target.value })
        .then((valid) => {
            setErrorLink(false);
            setErrorMessage("");
        })
        .catch((err) => {
            setErrorLink(true);
            setErrorMessage(t("profile.links.link.valid"));
        })
        onHandleEditLink(1, {id: link.id_link, value: event.target.value}); 
    }

    const handleChangeSocialMedia = (event) => {
        const selected = event.target.value;
        setSelectedValue(selected);
        onHandleEditLink(2, {id: link.id_link, value: selected.label, icon: selected.icon});
    };

    const handleDelete = () => {
        onHandleDeleteLink(link.id_link);
    }

    return (
        <div className='editable-link-item' >
            <div className='editable-link-container-dnd' {...onHandleDnd}>
                <MdDragIndicator className='editable-link-dnd' fontSize={30}/>
            </div>
            <div className="editable-link-container-right">
                <TextField
                      name="link"
                      className='editable-link-textfield'
                      variant="outlined"
                      value={link.social_url}
                      placeholder={t("profile.links.link.txt-link")}
                      error={errorLink}
                      helperText={errorLink && errorMessage}
                      onChange={handleChangeSocialUrl}
                    />
                <div className="editable-link-container-right-bottom">
                    <Select
                        id="editable-link-select"
                        value={valueSelected}
                        onChange={handleChangeSocialMedia}
                        displayEmpty
                        renderValue={(selected) => {
                            if (selected === null) {
                                return t("profile.links.link.select-media");
                            }
                            return selected.label;
                        }}
                        MenuProps={MenuProps}
                    >
                        {socialMediaOptions.sort((a, b) => a.label < b.label ? -1 : a.label > b.label ? 1 : 0).map((option) => (
                            <MenuItem key={option.id} value={option}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                    <IconButton color="primary" aria-label="" size="large" onClick={handleDelete}>
                        < BiTrash fontSize={25}/>
                    </IconButton>
                </div>
            </div>
        </div>
    );
};

export default Link;


