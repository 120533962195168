import React, {useState, useImperativeHandle, forwardRef} from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import './Snackbar.css';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

// eslint-disable-next-line
const CustomSnackbar = ({}, ref) => {
    const [open, setOpen] = useState(false);
    const [text, setText] = useState('')
    const [ype, setType] = useState('');

    useImperativeHandle(ref, () => ({
        open: (type, value) => {
                setSnackbar(type, value);
            }
    }), []);

    const setSnackbar = (type, value) => {
        setType(type);
        setText(value);
        setOpen(true);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return(
        <Snackbar className='snackbar-notifications' open={open} autoHideDuration={8000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={ype} sx={{ width: '100%' }}>
            {text}
          </Alert>
      </Snackbar>
    );
};

export default forwardRef(CustomSnackbar);