import './Home.css';
import { Container, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Nav from '../../Components/Nav/Nav';
import Footer from '../../Components/Footer/Footer';

function Home() {
  const { t } = useTranslation();
  return (
    <div className="section-home">
      <Container className='section-home-body' maxWidth="md">
          <Grid
            container
            className="home-grid-container"
            direction="column"
            justifyContent="center"
            alignItems="stretch"
          >
            <Typography className='title-home' variant="h2" gutterBottom component="h1" align='center'>
              {t("home.title")}
            </Typography>
            <Typography className='text-body' variant="h5" gutterBottom component="h5" align='center'>
              {t("home.subtitle")}
            </Typography>
          </Grid>
        </Container>
    </div>
  );
}

export default Home;
