import './Username.css';
import React, {useState} from 'react';
import axios from 'axios';
import { Button, Grid, CircularProgress, Paper, TextField, Typography, IconButton, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import regex from './usernameRegex';
import { useTranslation } from 'react-i18next';
import { FaRegCopy } from "react-icons/fa6";

const Username = ({lastUsername, currentUsername, user, snackbar, onHandleUpdateUsername }) => {
    const { t } = useTranslation();
    const [verifying, setVerifying] = useState(false);
    const [editing, setEditing] = useState(false);
    const [disabledSave, setDisabledSave] = useState(true);
    const [disabledEdit, setDisabledEdit] = useState(false);
    const [userNameValue, setUserNameValue] = useState(lastUsername);
    const [errorUsername, setErrorUsername] = useState(false);
    const [errorTextUsername, setErrorTextUsername] = useState('');
    const [copytext, setCopyText] = useState("");
    const [openCopy, setOpenCopy] = useState(false);
    
    const handleChangeUser = (e) => {
        const value = e.target.value.replace(/[\u0300-\u036f]|[^0-9a-z]|[#\s]/g, '');
        setUserNameValue(value);
        if (value.length > 30) {
            setErrorUsername(true);
            setDisabledSave(true);
            setErrorTextUsername(t("profile.username.maximum"));
        } else if (value.length <= 4) {
            setErrorUsername(true);
            setDisabledSave(true);
            setErrorTextUsername(t("profile.username.minimum"));
        } else if (value.match(regex)) {
            setErrorUsername(true);
            setDisabledSave(true);
            setErrorTextUsername(t("profile.username.allowed"));
        }else {
            setErrorUsername(false);
            setDisabledSave(false);
            setErrorTextUsername('');
        }
    }

    const handleVerifySave = async () => {
        if (lastUsername !== null) {
            if (lastUsername.toLowerCase() === userNameValue.toLowerCase()) {
                snackbar.current.open('info', t("profile.username.info"));
                return;
            }
        }
        if ((lastUsername === null && userNameValue !== null) || (lastUsername.toLowerCase() !== userNameValue.toLowerCase())) {
            setVerifying(true);
            setDisabledSave(true);
            setDisabledEdit(true);
            axios.post(`${process.env.REACT_APP_API_POST_SAVE_USER}`, {
            "userId": user.id,
            "username": userNameValue.toLowerCase()
            })
            .then(function (response) {
                const data = response.data;
                if (response.status === 200 && data.status === 1){
                    setEditing(!editing);
                    setDisabledSave(true);
                    onHandleUpdateUsername(userNameValue);
                    snackbar.current.open('success', t("profile.username.success"));
                }
                if (response.status === 200 && data.status === -1){
                    snackbar.current.open('error', t("profile.username.error.username-exist"));
                }
                if(response.status === 200 && data.status === -2){
                    snackbar.current.open('error', t("profile.username.error.unexpected"));
                }
                setVerifying(false);
                setDisabledEdit(false);
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    };
    
    const handleEdit = () => {
        if (editing){
            setUserNameValue(lastUsername !== null ? lastUsername : '');
            setErrorUsername(false);
            setErrorTextUsername('');
            setDisabledSave(true);
        }
        setEditing(!editing);
    };

    const handleCopyLink = () => {
        navigator.clipboard.writeText(`https://linkerto.me/${lastUsername}`)
        setCopyText(t("profile.username.copied"));
    }

    const handleCloseCopy = () => {
        setOpenCopy(!openCopy);
    };

    const handleOpenCopy = () => {
        setOpenCopy(!openCopy);
        setCopyText(t("profile.username.copy"));
    };

    return (
        <Paper variant='outlined' elevation={0} sx={{p: 2}}>
            <Box className="profile-username-header">
                <div>
                    <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
                        {t("profile.username.title")}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {t("profile.username.subtitle")}
                    </Typography>
                </div>  
                <Tooltip
                    open={openCopy}
                    onClose={handleCloseCopy}
                    onOpen={handleOpenCopy}
                    disableFocusListener
                    placement="top"
                    arrow
                    title={copytext}
                >
                    <IconButton onClick={handleCopyLink} className='profile-username-tooltip' color="primary" disabled={editing || !disabledSave || userNameValue == null || !window.isSecureContext}>
                        <FaRegCopy />
                    </IconButton>
                </Tooltip>
            </Box>
            <Grid container xs={12} >
                <Grid className={'profile-grid-username'} item xs={12} sx={{ display: 'flex', alignItems: 'center', marginTop: '1rem', marginBottom: '1rem'}}>
                    <Typography variant="body1" sx={{ color: 'text.secondary', marginRight: '0.2rem' }}>
                        linkerto.me/
                    </Typography>
                        <TextField
                        id="username"
                        fullWidth
                        variant="standard"
                        placeholder='myusername'
                        disabled={!editing}
                        value={userNameValue}
                        onChange={handleChangeUser}
                        error={errorUsername}
                        helperText={errorUsername && errorTextUsername}
                        />
                </Grid>
                <Grid item className='profile-username-actions' xs={12} sx={{ display: 'flex', flexDirection: {xs: 'column', sm: 'row', md: 'row'}, justifyContent: { xs: 'space-between' } }}>
                    <Button className='profile-btn-verify' color="primary" variant="outlined" size="large" disabled={disabledEdit} onClick={handleEdit}>
                        {!editing && t("profile.username.edit")}
                        {editing && t("profile.username.cancel-edit")}
                    </Button>
                    <Button className='profile-btn-verify' color="primary" variant="outlined" size="large" disabled={disabledSave} onClick={handleVerifySave}>
                        {verifying && <CircularProgress size={24} color="secondary" />}
                        {!verifying && t("profile.username.verify-save")}
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default Username;