import './PrivacyPolicy.css';
import { Container, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { LAST_UPDATE_PC } from "../../constants";
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';

const locales = {
	es: es,
	en: en
};

const PrivacyPolicy = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="section-pc">
      <div className="section-pc-container">
        <Container className='section-pc-body' maxWidth="md">
            <Grid
                container
                className="pc-grid-container"
                direction="column"
                justifyContent="center"
                alignItems="stretch"
            >
                <Typography className='title-pc' variant="h3" gutterBottom component="h3" align='center'>
                    {t("privacy-policy.title")}
                </Typography>
                <Typography className='subtitle-body' variant="subtitle2" gutterBottom align='left'>
                    {t("privacy-policy.last-update")}: {format(LAST_UPDATE_PC, 'PPP', {locale: locales[i18n.language]})}
                </Typography>
                <Typography className='text-body' variant="body2" gutterBottom align='left'>
                    {t("privacy-policy.description")}
                </Typography>
                <Typography className='subtitle-body' variant="body2" gutterBottom align='left'>
                    1. {t("privacy-policy.dot-1-title")}:
                </Typography>
                <Typography className='text-body' variant="body2" gutterBottom align='left'>
                    {t("privacy-policy.dot-1-value")}:
                    <ul>
                        <li>{t("privacy-policy.dot-1-item-1")}</li>
                        <li>{t("privacy-policy.dot-1-item-2")}</li>
                        <li>{t("privacy-policy.dot-1-item-3")}</li>
                    </ul>
                </Typography>
                <Typography className='subtitle-body' variant="body2" gutterBottom align='left'>
                    2. {t("privacy-policy.dot-2-title")}:
                </Typography>
                <Typography className='text-body' variant="body2" gutterBottom align='left'>
                    {t("privacy-policy.dot-2-value")}:
                    <ul>
                        <li>{t("privacy-policy.dot-2-item-1")}</li>
                        <li>{t("privacy-policy.dot-2-item-2")}</li>
                        <li>{t("privacy-policy.dot-2-item-3")}</li>
                        <li>{t("privacy-policy.dot-2-item-4")}</li>
                    </ul>
                </Typography>
                <Typography className='subtitle-body' variant="body2" gutterBottom align='left'>
                    3. {t("privacy-policy.dot-3-title")}:
                </Typography>
                <Typography className='text-body' variant="body2" gutterBottom align='left'>
                    {t("privacy-policy.dot-3-value")}
                </Typography>
                <Typography className='subtitle-body' variant="body2" gutterBottom align='left'>
                    4. {t("privacy-policy.dot-4-title")}:
                </Typography>
                <Typography className='text-body' variant="body2" gutterBottom align='left'>
                    {t("privacy-policy.dot-4-value")}
                </Typography>
                <Typography className='subtitle-body' variant="body2" gutterBottom align='left'>
                    5. {t("privacy-policy.dot-5-title")}:
                </Typography>
                <Typography className='text-body' variant="body2" gutterBottom align='left'>
                    {t("privacy-policy.dot-5-value")}
                </Typography>
                <Typography className='subtitle-body' variant="body2" gutterBottom align='left'>
                    6. {t("privacy-policy.dot-6-title")}:
                </Typography>
                <Typography className='text-body' variant="body2" gutterBottom align='left'>
                    {t("privacy-policy.dot-6-value")}
                </Typography>
                <Typography className='subtitle-body' variant="body2" gutterBottom align='left'>
                    7. {t("privacy-policy.dot-7-title")}:
                </Typography>
                <Typography className='text-body' variant="body2" gutterBottom align='left'>
                    {t("privacy-policy.dot-7-value-first")} <a href="mailto:contact@linkerto.com" className="text-link underline">contact@linkerto.com</a> {t("privacy-policy.dot-7-value-second")}
                </Typography>
                <Typography className='subtitle-body' variant="body2" gutterBottom align='left'>
                    8. {t("privacy-policy.dot-8-title")}:
                </Typography>
                <Typography className='text-body' variant="body2" gutterBottom align='left'>
                    {t("privacy-policy.dot-8-value")}
                </Typography>
                <Typography className='subtitle-body' variant="body2" gutterBottom align='left'>
                    {t("privacy-policy.contact-title")}:
                </Typography>
                <Typography className='text-body' variant="body2" gutterBottom align='left'>
                    {t("privacy-policy.contact-value")} <a href="mailto:contact@linkerto.com" className="text-link underline">contact@linkerto.com</a>.
                </Typography>
            </Grid>
        </Container>
      </div>
    </div>
  );
}

export default PrivacyPolicy;