import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { IconContext } from "react-icons";
import { HiMenu, HiOutlineLogout, HiOutlineLogin, HiOutlineUserAdd } from 'react-icons/hi';
import { FaUserCircle } from 'react-icons/fa';
import { AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, Button, MenuItem, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isAnon } from '../../utils';
import './Nav.css';

const Nav = ({user}) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [loggedIn, setLoggedIn] = useState(!isAnon(user));

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handlePushAndClose = (route, closeMenu) => {
    navigate(route);
    closeMenu();
  }

  useEffect(() => {
    setLoggedIn(!isAnon(user))
  }, [user])

  return (
    <AppBar position="static" className='section-nav' elevation={0}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
          <Link className="linkButton" to="/" >
            <Typography
              variant="h4"
              color="primary"
              noWrap
              component="h4"
              fontWeight={700}
              sx={{ mr: 2, display: { md: 'flex' } }}
            >
              Linkerto
            </Typography>
          </Link>
            <Box sx={{ flexGrow: 0, display: {xs: 'none', md: 'flex'}}}>
              {
                !loggedIn &&
                <Link className="linkButton" to="/login">
                  <Button className="login-btn" variant="outlined" size="medium" sx={{display: { xs: 'none', md: 'block' } }}>{t("nav.login")}</Button>
                </Link>
              }
              {
                !loggedIn && 
                <Link className="linkButton" to="/signup">
                  <Button color="secondary" variant="contained" size="medium" sx={{ display: { xs: 'none', md: 'block' } }}>{t("nav.signup")}</Button>
                </Link>
              }
              {loggedIn &&
                <IconButton
                  color="primary"
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenUserMenu}
                >
                  <HiMenu />
                </IconButton>
              }
              {loggedIn && 
                <IconContext.Provider value={{ color: "#e8e7e7", className: "react-icons-color" }}>
                  <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <MenuItem onClick={() => handlePushAndClose("/profile", handleCloseUserMenu)}>
                      <FaUserCircle className="iconProfileMenu" fontSize={20}/>
                      <Typography variant="body1" color="primary">{t("nav.profile")}</Typography>
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => handlePushAndClose("/logout", handleCloseUserMenu)}>
                      <HiOutlineLogout className="iconProfileMenu" fontSize={20}/>
                      <Typography variant="body1" color="primary">{t("nav.logout")}</Typography>
                    </MenuItem>
                  </Menu>
                </IconContext.Provider>
              }
            </Box>
            <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                color="primary"
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
              >
                <HiMenu />
              </IconButton>
              <IconContext.Provider value={{ color: "#e8e7e7", className: "react-icons-color" }}>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  {
                    !loggedIn &&
                    <MenuItem onClick={() => handlePushAndClose("/login", handleCloseNavMenu)}>
                      <HiOutlineLogin className="iconProfileMenu" fontSize={20}/>
                      <Typography variant="body1" color="primary">{t("nav.login")}</Typography>
                    </MenuItem>
                  }
                  {
                    loggedIn &&
                    <MenuItem onClick={() => handlePushAndClose("/profile", handleCloseNavMenu)}>
                      <FaUserCircle className="iconProfileMenu" fontSize={20}/>
                      <Typography variant="body1" color="primary">{t("nav.profile")}</Typography>
                    </MenuItem>
                  }
                  <Divider />
                  {
                    !loggedIn &&
                    <MenuItem onClick={() => handlePushAndClose("/signup", handleCloseNavMenu)}>
                      <HiOutlineUserAdd className="iconProfileMenu" fontSize={20}/>
                      <Typography variant="body1" color="primary">{t("nav.signup")}</Typography>
                    </MenuItem>
                  }
                  {
                    loggedIn &&
                    <MenuItem onClick={() => handlePushAndClose("/logout", handleCloseNavMenu)}>
                      <HiOutlineLogout className="iconProfileMenu" fontSize={20}/>
                      <Typography variant="body1" color="primary">{t("nav.logout")}</Typography>
                    </MenuItem>
                  }
                </Menu>
              </IconContext.Provider>
            </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Nav;