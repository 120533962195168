import "./UserLinksLayout.css";
import React, { useRef, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { HiOutlineLogin, HiOutlineLogout } from "react-icons/hi";
import { FaUserPlus, FaEdit } from "react-icons/fa";
import { FaCopy } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { Backdrop, SpeedDial, SpeedDialIcon, SpeedDialAction, Typography, Box, Divider, Fab } from '@mui/material';
import { useTranslation } from "react-i18next";

const actions = [
    { type: 'edit' ,icon: <FaEdit fontSize={18}/>, name: 'edit', visible: 'logged', extended: true },
    { type: 'signup' ,icon: <FaUserPlus fontSize={20}/>, name: 'signup', visible: 'anon', extended: true },
    { type: 'login' ,icon: <HiOutlineLogin fontSize={20}/>, name: 'login', visible: 'anon', extended: true },
    { type: 'copy' ,icon: <FaCopy fontSize={18}/>, name: 'copy', visible: 'public', extended: true },
    { type: 'logout' ,icon: <HiOutlineLogout fontSize={22}/>, name: 'logout', visible: 'logged', extended: true },
  ];

const UserLinksLayout = ({ isLogged = false }) => {
    const { t } = useTranslation();
    let { user } = useParams();
    let navigate  = useNavigate();
    const [open, setOpen] = useState(false);
    const [isCopiedText, setIsCopiedText] = useState(false);
    const visibleTo = isLogged ? 'logged' : 'anon';
    const copyTimerRef = useRef(null);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setTimeout(() => setIsCopiedText(false), 200);
    };

    const copyLink = async () => {
        try {
            await navigator.clipboard.writeText(`https://linkerto.me/${user}`);
            setIsCopiedText(true);
            if (copyTimerRef.current != null) {
                clearInterval(copyTimerRef.current)
            }
            copyTimerRef.current = setTimeout(() => setIsCopiedText(false), 5000);
        } catch (err) {
            console.log(err)
        }
    };

    const handleClickOption = (type) => {
        switch (type) {
            case 'copy':
                copyLink();
                break;
            case 'login':
                setOpen(false);
                setTimeout(() => navigate("/login"), 200);
                break;
            case 'logout':
                setOpen(false);
                setTimeout(() => navigate("/logout"), 200);
                break;
            case 'signup':
                setOpen(false);
                setTimeout(() => navigate("/signup"), 200);
                break;
            case 'edit':
                setOpen(false);
                setTimeout(() => navigate("/profile"), 200);
                break;
            default:
                setOpen(false);
                return;
        }
    }

    return (
        <div>
            <Outlet />
            <>
                <Backdrop open={open} />
                <SpeedDial
                    className='menu-user-float'
                    ariaLabel="User Menu"
                    sx={{ position: 'absolute', bottom: 16, right: 16 }}
                    icon={<SpeedDialIcon />}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    open={open}
                    FabProps={{
                        color: "primary"
                    }}
                >
                    {actions.filter((i => i.visible === visibleTo || i.visible === 'public')).map((action) => (
                        <SpeedDialAction
                            className='menu-user-action'
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.type === 'copy' && isCopiedText ? t("nav.copied") : t(`nav.${action.name}`)}
                            tooltipOpen
                            onClick={() => handleClickOption(action.type)}
                        />
                    ))}
                </SpeedDial>
                <div className="menu-divider">
                    <Divider />
                </div>
                <div className="menu-user-drawer">
                    <Link className="linkButton" to="/" >
                        <Typography
                                variant="h4"
                                color="primary"
                                noWrap
                                component="h2"
                                fontWeight={700}
                                sx={{ display: { md: 'flex' } }}
                            >
                                Linkerto
                        </Typography>
                    </Link>
                    <Box className='menu-fabs-container' sx={{ textAlign: 'center', pt: 1 }}>
                        {actions.filter((i => i.visible === visibleTo || i.visible === 'public')).map((action) => (
                                <Fab
                                    className='menu-user-action'
                                    key={action.name}
                                    variant={action.extended ? 'extended' : 'circular'}
                                    onClick={() => handleClickOption(action.type)}
                                >
                                    {action.icon}
                                    {
                                        action.extended &&
                                            <>
                                                {action.type === 'copy' && isCopiedText ? t("nav.copied") : t(`nav.${action.name}`)}
                                            </>
                                    }
                                </Fab>
                            ))}
                    </Box>
                </div>
            </>
        </div>
    )
}

export default UserLinksLayout;