import './Authentication.css';
import React, {useContext, useEffect, useState} from 'react';
import * as Realm from 'realm-web'
import * as yup from 'yup';
import { Button, Container, Grid, Box, Paper, CircularProgress, Typography, InputAdornment, IconButton, TextField, Checkbox } from '@mui/material';
import { useFormik } from 'formik';
import { IconContext } from "react-icons";
import { HiOutlineLogin, HiOutlineUserAdd } from 'react-icons/hi';
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { isAnon } from '../../utils';
import MongoContext from "../../MongoContext";

function Authentication ({ type }) {
  const { app, user, setUser } = useContext(MongoContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [badRequest, setBadRequest] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [checked, setChecked] = useState(false);
  
  useEffect(() => {	
    setBadRequest(false);
  }, [type]);

  const submitHandler = async (values) => {
    setLoading(true);
    try {
      if (type === 'create') {
        // const logged = await app.logIn(Realm.Credentials.anonymous());
        // setUser(logged);
        await app.emailPasswordAuth.registerUser({ email: values.email, password: values.password });
      }

      const credentials = Realm.Credentials.emailPassword(values.email, values.password);

      const user = await app.logIn(credentials);
      console.assert(user.id === app.currentUser.id);
      setUser(user);
    } catch (err) {
      switch(err.statusCode) {
        case 401:
          setErrorMessage(t("auth.error.invalid"));
          break;
        case 409:
          setErrorMessage(t("auth.error.in-use"));
          break;
        default:
          setErrorMessage(t("auth.error.bad-request"));
          break;
      }
      setBadRequest(true);
    }
    setLoading(false);
  }

  useEffect(() => {	
    if (!isAnon(user)) {
      navigate('/profile', { replace: true });
    }
    setBadRequest(false);
  }, [navigate, user]);

  const userSchema = yup.object({
    email: yup
      .string(t("auth.email-string"))
      .email(t("auth.email-email"))
      .required(t("auth.email-required")),
    password: yup
      .string(t("auth.password-string"))
      .min(8, t("auth.password-min"))
      .required(t("auth.password-required")),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: userSchema,
    onSubmit: (values) => {
      submitHandler(values)
    },
  });

  const handleChange = (e) => {
    badRequest && setBadRequest(false);
    formik.handleChange(e);
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleAccepted = (event) => {
    setChecked(event.target.checked);
  };

  return (
      <div className="section-auth">
        <Container className='section-auth-body' maxWidth="md">
            <Grid
              container
              className="auth-grid-container"
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
            <Box className="auth-box">
              <Paper className="auth-paper" variant='outlined' elevation={0}>
                <Box
                  className="auth-form-login"
                  component="form"
                  onSubmit={formik.handleSubmit}
                  autoComplete="off"
                  >
                    <div className='auth-form-header'>
                      <IconContext.Provider value={{ color: "#e8e7e7", className: "react-icons-color" }}>
                        <div className='auth-form-header-title'>
                          {type === 'login' ? <HiOutlineLogin className="iconProfileMenu" fontSize={30}/> : <HiOutlineUserAdd className="iconProfileMenu" fontSize={30}/>}
                          <Typography variant="h5"  component="div" color="primary">
                            {type === 'login' && <>{t("auth.login-title")}</>}
                            {type === 'create' && <>{t("auth.signup-title")}</>}
                          </Typography>
                        </div>
                        {type === 'create' &&
                          <Typography className='auth-form-header-body' textAlign='center' color='primary' variant="body1" >
                            {t("auth.signup-body")}
                          </Typography>}
                      </IconContext.Provider>
                    </div>
                    <TextField
                      color="primary"
                      fullWidth
                      id="email"
                      name="email"
                      label={t("auth.email")}
                      margin="normal"
                      value={formik.values.email}
                      onChange={(e) => handleChange(e)}
                      error={formik.touched.email && Boolean(formik.errors.email) ? Boolean(formik.errors.email) : badRequest}
                      helperText={formik.touched.email && formik.errors.email}
                      disabled={loading}
                    />
                    <TextField
                      color="primary"
                      fullWidth
                      id="password"
                      name="password"
                      label={t("auth.password")}
                      type={showPassword ? 'text' : 'password'}
                      margin="normal"
                      value={formik.values.password}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                        </IconButton>
                      </InputAdornment>,
                      }}
                      onChange={(e) => handleChange(e)}
                      error={formik.touched.password && Boolean(formik.errors.password) ? Boolean(formik.errors.password) : badRequest}
                      helperText={formik.touched.password && formik.errors.password}
                      disabled={loading}
                    />
                    {
                      badRequest &&
                      <Typography variant="body2" className='auth-request-error'>
                        {errorMessage}
                      </Typography>
                    }
                    {
                      type === 'login' &&
                      <Typography className="auth-forgot-password" variant="body2" >
                        <Link className='text-link underline white-text' to="/sendPasswordReset" >
                        {t("auth.forgot-password")}
                        </Link>
                      </Typography>
                    }
                    {
                      type === 'create' &&
                      <Typography className='accepted-terms-privacy' variant="caption" >
                        <Checkbox
                          className="accepted-checkbox"
                          disabled={loading}
                          checked={checked}
                          onChange={handleAccepted}
                          inputProps={{ 'aria-label': 'controlled' }}
                          size="small"
                        />
                        <span>
                          {t("auth.accepted-first")} <Link className='text-link underline lowercase' to="/termsAndConditions">{t("terms-and-conditions.title")}</Link>{t("auth.accepted-second")} <Link className='text-link underline lowercase' to="/privacyPolicy">{t("privacy-policy.title")}</Link>*
                        </span>
                      </Typography>
                    }
                    <Button className='auth-btn' disabled={loading || (type === 'create' && !checked)} color="primary" variant="contained" size="large" type="submit">
                      {loading && <CircularProgress size={27} color="secondary" />}
                      {type === 'login' && !loading && <>{t("auth.login-commit")}</>}
                      {type === 'create' && !loading && <>{t("auth.signup-commit")}</>}
                    </Button>
                </Box>
              </Paper>
            </Box>
          </Grid>
        </Container>
    </div>
  );
}

export default Authentication;