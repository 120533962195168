import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import DynamicIcon from '../DynamicIcon/DynamicIcon';
import './Links.css';

function    Links({ links, theme, origin, className }) {
    const styles = {
        textLink: {
            color: theme.text_color_button ? theme.text_color_button : '#fff'
        },
        buttonColor: {
            backgroundColor: theme.background_color_button ? theme.background_color_button : 'transparent',
            border: theme.border_button ? theme.border_button : '2px solid #ffffff'
        }
    }

    return (
            <Stack
            className={className}
            direction="column"
            justifyContent="space-around"
            alignItems="strech"
            spacing={2}
            >
                {links.map((link, index) => {
                    return <Button
                            key={`button-${index}-key`}
                            target="_blank"
                            style={styles.buttonColor}
                            className={`button-link ${origin === 'preview' && 'disabled-link'}`}
                            variant='contained'
                            size="large"
                            startIcon={<DynamicIcon name={link.icon_name} color={styles.textLink.color}/>} 
                            href={`${link.social_url}`}>
                                <span key={`span-${index}-key`} id={`${index}-link`} style={styles.textLink} className={'text-link'}>{link.social_name ? link.social_name : 'Social media'}</span>
                            </Button>
                })}
            </Stack>
    );
}
export default Links;