import './Profile.css';
import React, {useState, useEffect, useRef, useReducer, useContext} from 'react';
import axios from 'axios';
import { format } from 'date-fns'
import { IoClose } from "react-icons/io5";
import { Container, Grid, Typography, Button, Alert, IconButton, Collapse, CircularProgress, Skeleton  } from '@mui/material';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import Snackbar from '../../Components/Snackbar/Snackbar';
import Username from '../../Components/Username/Username';
import EditableLinks from '../../Components/EditableLinks/EditableLinks';
import EditableTheme from '../../Components/EditableTheme/EditableTheme';
import EditableInfo from '../../Components/EditableInfo/EditableInfo';
import PreviewLinks from '../../Components/PreviewLinks/PreviewLinks';
import Footer from '../../Components/Footer/Footer';
import { isValidateDesc, isValidateTitle } from '../../utils';
import MongoContext from "../../MongoContext";
import { initialState, profileReducer } from '../../reducer/profileReducer';
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import { Link } from 'react-router-dom';

const locales = {
	es: es,
	en: en
};

function Profile({theme}) {
  const { t, i18n } = useTranslation();
  const [profileDataState, dispatch] = useReducer(profileReducer, initialState);
  const [loading, setLoading] = useState(true);
  const [pending, setPending] = useState(false);
  const [existChange, setExistChange] = useState(false);
  const [openDeployed, setOpenDeployed] = useState(false);
  const snackbar = useRef(null);
  const { user } = useContext(MongoContext);
  
  const linkSchema = yup.object({
    link: yup
      .string(t("profile.link-string"))
      .url()
      .required(),
  });

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_GET_DATA_PROFILE}${user.id}`)
    .then(function (response) {
      const { data } = response;
      if (response.status === 200 && data.status === 1){
        let linksBefore = [];
        if (data.result.links) {
          linksBefore = data.result.links.sort((a, b) => {
            return a.position - b.position
          });
        } else {
          linksBefore = []
        }
        dispatch({
          type: 'SET_PROFILE_DATA',
          payload: {
            username: data.result.username ? data.result.username : null,
            links: linksBefore,
            theme: data.result.theme ? data.result.theme : {
              title_color_user: "rgba(255, 255, 255, 1)",
              text_color_button: "rgba(255, 255, 255, 1)",
              background_color_button: "rgba(255, 255, 255, 0)",
              border_button: "1px solid rgba(255, 255, 255, 1)",
              left_gradient: "rgba(18, 36, 53, 1)",
              right_gradient: "rgba(18, 36, 53, 1)"
            },
            info: data.result.info ? data.result.info : {
              title: '',
              description: ''
            },
            isPublished: data.result.isPublished ? data.result.isPublished : false,
            publishDate: data.result.publishDate ? data.result.publishDate : null,
            isDraft: data.result.isDraft ? data.result.isDraft : false
          }
        });
        setLoading(false);
        setTimeout(() => {
          (data.result.isDraft || data.result.isPublished) && setOpenDeployed(true);
        }, 1000)
      }
      if(response.status === 200 && data.status === -1){
        //usuario no encontrado
        snackbar.current.open('error', t("profile.error.internal-error"));
      }
    })
    .catch(function (error) {
      console.log(error);
      snackbar.current.open('error', t("profile.error.internal-error"));
    });
  // eslint-disable-next-line
  }, []);

  const handleAddLink = (link) => {
    dispatch({ type: 'ADD_LINK', payload: link })
  }

  const handleDeleteLink = (link) => {
    dispatch({ type: 'DELETE_LINK', payload: link })
  }

  const handleEditLink = (type, link) => {
    switch (type) {
      case 1:
        return dispatch({ type: 'UPDATE_LINK', payload: link });
      case 2:
        return dispatch({ type: 'UPDATE_SOCIAL_MEDIA_TYPE', payload: link });
      default:
        break
    }
  }

  const handleEditTheme = (type, value) => {
    dispatch({ type: 'UPDATE_THEME_OBJECT', payload: { type: type, value: value } });
  }

  const handleEditInfo = (type, value) => {
    dispatch({ type: 'UPDATE_INFO_OBJECT', payload: { type: type, value: value } });
  }

  const handleUpdateUsername = username => {
    dispatch({ type: 'UPDATE_USER', payload: username })
  }

  const handleUpdateLastProfile = profile => {
    dispatch({ type: 'UPDATE_LAST_PROFILE', payload: profile })
  }

  const handleUpdatePublish = publish => {
    dispatch({ type: 'UPDATE_PUBLISH', payload: publish })
  }

  const handleUpdateDraft = draft => {
    dispatch({ type: 'UPDATE_DRAFT', payload: draft })
  }

  const handleEditPosition = (destination, source) => {
    dispatch({ type: 'UPDATE_LINK_POSITION', payload: { destination: destination, source: source } })
  }

  const statusLink = link => linkSchema.isValidSync({ link: link.social_url });

  const completeVeryfier = profile => {
    if (profile.username == null || profile.username.length === 0) {
      return {valid: false, message: t("profile.veryfier.username")};
    }
    if (profile.links == null || profile.links.length === 0) {
      return {valid: false, message: t("profile.veryfier.links-length")};
    }
    if (!profile.links.every(statusLink)) {
      return {valid: false, message: t("profile.veryfier.links-status")};
    }
    return {valid: true};
  }

  const verifySave = profile => {
    if (!isValidateTitle(profile.info.title)) {
      return {valid: false, message: t("profile.veryfier.title-length")};
    }
    if (!isValidateDesc(profile.info.description)) {
      return {valid: false, message: t("profile.veryfier.desc-length")};
    }
    return {valid: true};
  }

  const handleSaveDraft = () => {
    const resultVerifySave = verifySave(profileDataState.currentProfile)
    if (resultVerifySave.valid) {
      setPending(!pending);
      axios.post(process.env.REACT_APP_API_POST_SAVE_DATA, {
      "userId": user.id,
      "data": {
        "username": profileDataState.currentProfile.username,
        "links": profileDataState.currentProfile.links,
        "theme": profileDataState.currentProfile.theme,
        "info": profileDataState.currentProfile.info,
        "isDraft": true,
        "isPublished": profileDataState.isPublished,
        "publishDate": profileDataState.publishDate
      }
      })
      .then(function (response) {
          const data = response.data;
          if (response.status === 200 && data.status === 1){
            snackbar.current.open('success', t("profile.success.save-draft"));
            handleUpdateDraft(true);
            setExistChange(false);
            handleUpdateLastProfile(profileDataState.currentProfile);
          }
          if (response.status === 200 && data.status === -1){
            snackbar.current.open('error', t("profile.error.internal-error"));
          }
          setPending(false);
      })
      .catch(function (error) {
          console.log(error);
          snackbar.current.open('error', t("profile.error.internal-error"));
          setPending(false);
      });
    } else {
      snackbar.current.open('error', resultVerifySave.message);
    }
  }

  const handleDeploy = () => {
    const resultVeryfier = completeVeryfier(profileDataState.currentProfile);
    if (resultVeryfier.valid) {
      setPending(!pending);
      const publishDate = new Date();
      axios.post(process.env.REACT_APP_API_POST_PUBLISH_LINKS, {
      "userId": user.id,
      "data": {
        "publishDate": publishDate,
        "isDraft": false,
        "isPublished": true
      }
      })
      .then(function (response) {
          const data = response.data;
          if (response.status === 200 && data.status === 1){
            snackbar.current.open('success', t("profile.success.published"));
            handleUpdatePublish({isPublished: true, publishDate: `${publishDate}`});
            handleUpdateDraft(false);
          }
          if (response.status === 400 && data.status === -1){
            snackbar.current.open('error', t("profile.error.internal-error"));
          }
          setPending(false);
      })
      .catch(function (error) {
          snackbar.current.open('error', t("profile.error.internal-error"));
      });
    } else {
      snackbar.current.open('error', resultVeryfier.message);
    }
  }

  useEffect(() => {
    if (JSON.stringify(profileDataState.lastProfile) !== JSON.stringify(profileDataState.currentProfile)) {
      setExistChange(true);
      setOpenDeployed(true);
    } else if (JSON.stringify(profileDataState.lastProfile) === JSON.stringify(profileDataState.currentProfile)) {
      setExistChange(false);
      if (!profileDataState.isDraft && !profileDataState.isPublished) {
        setOpenDeployed(false);
      }
    }
  }, [profileDataState])

  return (
    <div className="section-profile">
      {
        loading ?
        <div className='section-profile-container'>
          <Container className='section-profile-body' maxWidth="lg">
            <Grid
              container
              className="profile-grid-container"
              spacing={2}
            >
              <Grid item className='title-profile-grid-skeleton' xs={12} md={12}>
                <Typography className='title-profile-skeleton' variant="h3" gutterBottom component="h3" align='center'>
                  {t("profile.title")}
                </Typography>
              </Grid>
              <Grid className='profile-grid-skeleton' item xs={12} md={6}>
                <Skeleton variant="rectangular" />
                <Skeleton variant="rectangular"/>
              </Grid>
              <Grid className='profile-grid-skeleton' item xs={12} md={6}>
                <Skeleton variant="rectangular"/>
                <Skeleton variant="rectangular"/>
              </Grid>
            </Grid>
          </Container>
          <Footer />
        </div>
        :
        <div className='section-profile-container'>
          <Container className='section-profile-body' maxWidth="lg">
            <Grid
              container
              className="profile-grid-container"
              spacing={2}
            >
              <Grid item xs={12} md={12}>
                <Typography className='title-profile' variant="h3" component="h3" align='center'>
                  {t("profile.title")}
                </Typography>
              </Grid>
              <Grid className='sp-grid-alert-action' item xs={12} md={12}>
                  <Collapse in={openDeployed}>
                    <Alert
                      className='section-profile-alert-action'
                      icon={false}
                      variant="filled"
                      severity={!existChange && !profileDataState.isDraft && profileDataState.isPublished ? 'success' : 'info'}
                      action={
                        !existChange && !profileDataState.isDraft && profileDataState.isPublished ?
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setOpenDeployed(false);
                          }}
                        >
                          <IoClose />
                        </IconButton> :
                        undefined
                      }
                    >
                      {
                        existChange ?
                        t("profile.save-draft")
                        :
                        profileDataState.isDraft ? 
                        t("profile.publish-links")
                        :
                        profileDataState.isPublished ? 
                        <span>{t("profile.published-links")} {format(new Date(profileDataState.publishDate), 'PPPppp', {locale: locales[i18n.language]})}.</span>
                        :
                        ''
                      }
                      {
                      (existChange || profileDataState.isDraft) && <Button variant="contained" onClick={existChange ? handleSaveDraft : handleDeploy} disabled={pending}>
                        {pending && <CircularProgress size={24} color="secondary" />}
                        {!pending && (existChange ? t("profile.commit-1") : t("profile.commit-2"))}
                      </Button>
                      }
                      {
                        (!existChange && !profileDataState.isDraft && profileDataState.isPublished) && <Button variant="contained" sx={{ color:'#1a1a1a' }} component={Link} to={`/${profileDataState.currentProfile.username}`}>
                        {t("profile.see-now")}
                      </Button>
                      }
                    </Alert>
                  </Collapse>
              </Grid>
              <Grid item xs={12} md={6}>
                <Username
                  lastUsername={profileDataState.lastProfile.username}
                  currentUsername={profileDataState.currentProfile.username}
                  user={user}
                  snackbar={snackbar}
                  onHandleUpdateUsername={handleUpdateUsername}
                />
                <EditableInfo
                  currentInfo={profileDataState.currentProfile.info}
                  currentUsername={profileDataState.currentProfile.username}
                  onHandleEditInfo={handleEditInfo}
                />
                <EditableLinks
                  lastLinks={profileDataState.lastProfile.links}
                  currentLinks={profileDataState.currentProfile.links}
                  onHandleAddLink={handleAddLink}
                  onHandleDeleteLink={handleDeleteLink}
                  onHandleEditLink={handleEditLink}
                  onHandleEditPosition={handleEditPosition}
                  onHandleEditTheme={handleEditTheme}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <EditableTheme
                  onHandleEditTheme={handleEditTheme}
                  baseTitleClrUsr={profileDataState.currentProfile.theme.title_color_user.match(/(\d+(?:\.\d+)?)/g)}
                  baseButtonTxtClr={profileDataState.currentProfile.theme.text_color_button.match(/(\d+(?:\.\d+)?)/g)}
                  baseBackgroundClrBtn={profileDataState.currentProfile.theme.background_color_button.match(/(\d+(?:\.\d+)?)/g)}
                  baseBorderBtn={profileDataState.currentProfile.theme.border_button.slice(4).match(/(\d+(?:\.\d+)?)/g)}
                  baseBorderWidth={profileDataState.currentProfile.theme.border_button.split(' ')[0]}
                  baseLeftGrd={profileDataState.currentProfile.theme.left_gradient.match(/(\d+(?:\.\d+)?)/g)}
                  baseRightGrd={profileDataState.currentProfile.theme.right_gradient.match(/(\d+(?:\.\d+)?)/g)}
                />
                <PreviewLinks
                  username={profileDataState.currentProfile.username}
                  links={profileDataState.currentProfile.links}
                  theme={profileDataState.currentProfile.theme}
                  info={profileDataState.currentProfile.info}
                />
              </Grid>
            </Grid>
          </Container>
        </div>
      }
      <Snackbar ref={snackbar}/>
    </div>
  );
}

export default Profile;
