import './SendPasswordReset.css';
import React, {useEffect, useState, useRef, useContext} from 'react';
import * as yup from 'yup';
import { Container, Grid, Box, Paper, CircularProgress, Typography, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { Button  } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Snackbar from '../../Components/Snackbar/Snackbar';
import MongoContext from "../../MongoContext";

const SendPasswordReset = () => {
  const { app, user } = useContext(MongoContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [badRequest, setBadRequest] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const snackbar = useRef(null);

  const submitHandler = async (values) => {
    setLoading(true);
    try {
      await app.emailPasswordAuth.sendResetPasswordEmail({ email: values.email });
      snackbar.current.open('success', t("pre-recovery.success"));
    } catch (err) {
      switch(err.statusCode) {
        default:
          setErrorMessage(t("pre-recovery.error.bad-request"));
          break;
      }
      setBadRequest(true);
    }
    setLoading(false);
  }

  useEffect(() => {	
    setBadRequest(false);
  }, [navigate, user]);

  const userSchema = yup.object({
    email: yup
      .string(t("auth.email-string"))
      .email(t("auth.email-email"))
      .required(t("auth.email-required"))
  });

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: userSchema,
    onSubmit: (values) => {
      submitHandler(values)
    },
  });

  const handleChange = (e) => {
    badRequest && setBadRequest(false);
    formik.handleChange(e);
  }

  return (
      <div className="section-prereset-password">
        <Container className='section-prepass-body' maxWidth="md">
            <Grid
              container
              className="prepass-grid-container"
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
            <Box className="prepass-box">
              <Paper className="prepass-paper" variant='outlined' elevation={0}>
                <Box
                  className="prepass-form"
                  component="form"
                  onSubmit={formik.handleSubmit}
                  autoComplete="off"
                  >
                    <div className='prepass-form-header'>
                      <div className='prepass-form-header-title'>
                        <Typography variant="h5"  component="div" color="primary">
                          {t("pre-recovery.title")}
                        </Typography>
                      </div>
                      <Typography className='prepass-form-header-body' textAlign='left' color='primary' variant="body1" >
                        {t("pre-recovery.subtitle")}
                      </Typography>
                      <Typography className='prepass-form-header-body' textAlign='left' sx={{ color: 'text.secondary'}} variant="body2" >
                        {t("pre-recovery.body")}
                      </Typography>
                    </div>
                    <TextField
                      color="primary"
                      fullWidth
                      id="email"
                      name="email"
                      label={t("pre-recovery.email")}
                      margin="normal"
                      value={formik.values.email}
                      onChange={(e) => handleChange(e)}
                      error={formik.touched.email && Boolean(formik.errors.email) ? Boolean(formik.errors.email) : badRequest}
                      helperText={formik.touched.email && formik.errors.email}
                      disabled={loading}
                    />
                    {
                      badRequest &&
                      <Typography variant="body2" className='prepass-request-error'>
                        {errorMessage}
                      </Typography>
                    }
                    <Button className='prepass-btn' disabled={loading} color="primary" variant="contained" size="large" type="submit">
                      {loading && <CircularProgress size={27} color="secondary" />}
                      {!loading && <>{t("pre-recovery.commit")}</>}
                    </Button>
                </Box>
              </Paper>
            </Box>
          </Grid>
        </Container>
        <Snackbar ref={snackbar}/>
    </div>
  );
}

export default SendPasswordReset;