export const initialState = {
    lastProfile: {
      username: null,
      links: [],
      theme: {},
      info: {}
    },
    currentProfile: {
      username: null,
      links: [],
      theme: {},
      info: {}
    },
    isDraft: false,
    isPublished: false,
    publishDate: null
  }

export const profileReducer = (state, action) => {
    switch (action.type) {
        case 'SET_PROFILE_DATA':
          return {
            ...state,
            lastProfile: {
              username:  action.payload.username,
              links:  action.payload.links,
              theme: action.payload.theme,
              info: action.payload.info
            },
            currentProfile: {
              username:  action.payload.username,
              links:  action.payload.links,
              theme: action.payload.theme,
              info: action.payload.info
            },
            isDraft: action.payload.isDraft,
            isPublished: action.payload.isPublished,
            publishDate: action.payload.publishDate
          }
        case 'ADD_LINK':
            return {
                ...state,
                currentProfile: {
                  ...state.currentProfile,
                  links: [...state.currentProfile.links, action.payload]
                }
            }
        case 'UPDATE_LINK':
          return {
            ...state,
            currentProfile: {
              ...state.currentProfile,
              links: state.currentProfile.links.map(link => (
                link.id_link === action.payload.id ?
                {
                  ...link,
                  social_url: action.payload.value
                } : link
              ))
            }
          }
        case 'UPDATE_LINK_POSITION':
          const links = state.currentProfile.links;
          const cloneLinks = [...links];
  
          const [removed] = cloneLinks.splice(action.payload.source, 1);
          cloneLinks.splice(action.payload.destination, 0, removed);
          const linksWithIndex = cloneLinks.map((link, index) => ({
            ...link,
            position: index
          }))
          return  {
            ...state,
            currentProfile: {
              ...state.currentProfile,
              links: linksWithIndex
            }
          }
        case 'UPDATE_SOCIAL_MEDIA_TYPE':
          return {
            ...state,
            currentProfile: {
              ...state.currentProfile,
              links: state.currentProfile.links.map(link => (
                link.id_link === action.payload.id ?
                {
                  ...link,
                  social_name: action.payload.value,
                  icon_name: action.payload.icon
                } : link
              ))
            }
          }
        case 'DELETE_LINK':
          return {
              ...state,
              currentProfile: {
                ...state.currentProfile,
                links: state.currentProfile.links.filter(link => link.id_link !== action.payload).map((link, index) => ({
                  ...link,
                  position: index
                }))
              }
          }
        case 'UPDATE_USER':
          return {
              ...state,
              lastProfile: {
                ...state.currentProfile,
                username: action.payload
              },
              currentProfile: {
                ...state.currentProfile,
                username: action.payload
              }
          }
        case 'UPDATE_THEME_OBJECT':
          return {
            ...state,
            currentProfile: {
              ...state.currentProfile,
              theme: {
                ...state.currentProfile.theme,
                [action.payload.type]: action.payload.value
              }
            }
          }
        case 'UPDATE_INFO_OBJECT':
          return {
            ...state,
            currentProfile: {
              ...state.currentProfile,
              info: {
                ...state.currentProfile.info,
                [action.payload.type]: action.payload.value
              }
            }
          }
        case 'UPDATE_LAST_PROFILE':
          return {
              ...state,
              lastProfile: action.payload
          }
        case 'UPDATE_DRAFT':
          return {
            ...state,
            isDraft: action.payload,
          }
        case 'UPDATE_PUBLISH':
          return {
            ...state,
            isPublished: action.payload.isPublished,
            publishDate: action.payload.publishDate,
            isDraft: false
          }
        default: 
            return state;
    }
}