export const socialMediaOptions = [
  { id: 1, label: 'facebook' , icon: 'FaFacebookF' },
  { id: 2, label: 'twitter', icon: 'FaXTwitter' },
  { id: 3, label: 'instagram' , icon: 'FaInstagram' },
  { id: 4, label: 'twitch' , icon: 'FaTwitch' },
  { id: 5, label: 'linkedin', icon: 'FaLinkedinIn' },
  { id: 6, label: 'github', icon: 'FaGithub' },
  { id: 7, label: 'youtube', icon: 'FaYoutube' },
  { id: 8, label: 'dev.to', icon: 'FaDev' },
  { id: 9, label: 'discord', icon: 'FaDiscord' },
  { id: 10, label: 'hackerrank', icon: 'FaHackerrank' },
  { id: 11, label: 'dribbble', icon: 'FaDribbble' },
  { id: 12, label: 'tiktok', icon: 'FaTiktok' },
  { id: 13, label: 'link' , icon: 'FaLink' },
  { id: 14, label: 'medium', icon: 'FaMedium' },
  { id: 15, label: 'skype', icon: 'FaSkype' },
  { id: 16, label: 'spotify', icon: 'FaSpotify' },
  { id: 17, label: 'pinterest', icon: 'FaPinterest' },
  { id: 18, label: 'snapchat', icon: 'FaSnapchat' },
  { id: 19, label: 'reddit', icon: 'FaRedditAlien' },
  { id: 20, label: 'telegram', icon: 'FaTelegramPlane' },
  { id: 21, label: 'threads', icon: 'FaThreads' },
  { id: 22, label: 'tumblr', icon: 'FaTumblr' }
];